/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2020-11-11 18:07:24
 * @LastEditors: tina.cai
 * @LastEditTime: 2023-05-20 12:44:01
 */
import VueI18n from "vue-i18n"
import Vue from "vue"

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem('lang') || "en-US", //语言标识
  messages: {
    "en-US": require("./locales/en-US.json"), // 英文
    "id-ID": require("./locales/id-ID.json"), // 印尼语
    "es-MX": require("./locales/es-MX.json"), // 西班牙-墨西哥
    "pt-BR": require("./locales/pt-BR.json"), // 葡萄牙-巴西
    "th-TH": require("./locales/th-TH.json"), // 泰语
    "vi-VN": require("./locales/vi-VN.json"), // 泰语
  },
  fallbackLocale: "en-US",
})
