/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2023-05-16 23:56:25
 * @LastEditors: tina.cai
 * @LastEditTime: 2023-06-14 23:53:10
 */
import Vue from 'vue';
import axios from 'axios';
import env from "./env";
// import MD5 from 'crypto-js/md5';

/* eslint-disable */
let MD5 = CryptoJS.MD5;

const baseUrl = env.baseUrl
const secretKey = env.key

const merchantId = env.merchantId;
const version = '1.0';
const currency = 'IDR';

export const trim = data => {
  if (data) {
    Object.keys(data).forEach(key => {
      console.log(key, 'key', typeof data[key])
      if (typeof data[key] === 'string') data[key] = data[key].trim();
    });
  }
};

Vue.prototype.$httpReqest = (url = false, paramsJson = '') => {
  if (!url) return;
  trim(paramsJson);
  const sigData = Object.assign(paramsJson ,{ merchantId, version, currency });
  const param = Object.keys(sigData)
    .sort()
    .map(key => `${key}=${sigData[key]}`)
    .join('&'); // 字典排序再组合
  
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + url,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      timeout: 30000,
      data: `sign=${MD5(param + secretKey
        ).toString()}&${param.replace(/encrypt=(.+?)(?=&)/, (input, $1) => {
          return `encrypt=${encodeURIComponent($1)}` || '';
        })}`
    })
      .then(res => {
        console.log('==res==', res);
        try {
          res.data.status = res.status;
          // res.data.msg = res.data.msg;
          res = res.data;
          
        } catch (e) {
          console.error(e);
        }
        resolve(res);
      })
      .catch(error => {
        // timeout要处理
        if (error && error.message && /^timeout/i.test(error.message)) {
          resolve({ status: 'BC500', walletStatusMsg: 'Timeout, please try' });
        } else reject(error);
      });
  });
};

