<template>
  <div class="header-main">
    <div class="mask" @click="close" v-if="(searchVal && isSearchShow) || (isMobile && showSearchFlag)"></div>
    <a-row type="flex" class="row-1">
      <a-col class="logo-hd-box" :flex="1">
        <router-link to="/">
          <img :src="logo" class="logo-hd" />
        </router-link>
      </a-col>
      <a-col class="lang-box" :flex="!isMobile ? 1 : ''">
        <div class="search" v-if="!isMobile">
          <SearchBox @onsearch="handleSearch" :searchVal="searchVal" :clear="clearValue" ref="search" />
          <div v-if="searchVal && isSearchShow" class="search-result-box">
            <p v-if="gameListInfo.length <= 0" class="no-data">{{$t('no_results_found')}}</p>
            <ul class="search-result-list" v-if="gameListInfo.length > 0 ">
              <li @click="toPayCheckout(item.id)" v-for="item in gameListInfo" :key="item.id">
                <div class="content">
                  <div class="game-pic">
                    <img
                      :src="getImageRealPath(item.gamePic)"
                    />
                  </div>
                  <p class="name" v-html="highlightedItem(item.gameName)" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <i class="search-icon" v-if="isMobile && !showSearchFlag"  @click="showSearch" />
        <a-dropdown placement="bottomRight">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <i
              :class="'icon-country icon-country-' + country.toString().toLowerCase()"
            ></i>
            <span class="txt">{{ country }}</span><a-icon type="down" />
          </a>
          <a-menu class="lang-menu" slot="overlay" @click="changeLang">
            <a-menu-item class="lang-menu-item"  v-for="(item, index) in lang" :key="index">
              <a href="javascript:;">
                <i
                :class=" `icon-country icon-country-${item.label}`"
              ></i>
                {{ item.label }}
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-col>
    </a-row>
    <a-row type="flex" v-if="isMobile && showSearchFlag" class="row-2">
      <div class="search" >
        <SearchBox @onsearch="handleSearch" :searchVal="searchVal" :clear="clearValue" ref="search" />
        <div v-if="searchVal && isSearchShow" class="search-result-box">
          <p v-if="gameListInfo.length <= 0" class="no-data">{{$t('no_results_found')}}</p>
          <ul class="search-result-list" v-if="gameListInfo.length > 0 ">
            <li @click="toPayCheckout(item.id)" v-for="item in gameListInfo" :key="item.id">
              <div class="content">
                <div class="game-pic">
                  <img
                    :src="getImageRealPath(item.gamePic)"
                  />
                </div>
                <p class="name" v-html="highlightedItem(item.gameName)" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </a-row>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import SearchBox from './components/searchC.vue'
import i18n from '../i18n'
import utils from '@/assets/js/common';
export default {
  components: {
    SearchBox
  },
  data() {
    return {
      showSearchFlag: false,
      clickJump: false,
      isMobile: false,
      logo: require('../assets/image/kuku-logo.png'),
      searchVal: '',
      isSearchShow: false,
      country: 'EN',
      lang: [
        { key: "en-US", label: "EN" },
      ],
      gameListInfo: [],
      currency: this.$store.state.orderInfo.gameCurrency
    }
  },
  mounted() {
    if (this._isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    const currentLang = localStorage.getItem('lang')

    if(this.currency == null) {
      const localHost = location.host;
      if (localHost.indexOf('la') > 0) {
        this.lang.push(
            { key: 'pt-BR', label: 'BR' },
            { key: 'es-MX', label: 'MX' })
      } else if (localHost.indexOf('th') > 0) {
        this.lang.push(
            { key: 'th-TH', label: 'TH' })
      } else if (localHost.indexOf('ph') > 0) {
        this.lang.push(
            { key: 'fil-PH', label: 'PH' })
      } else if (localHost.indexOf('pg') > 0) {
        this.lang.push(
            { key: 'my-MM', label: 'MM' },
            // { key: 'en-SG', label: 'SG' },
            { key: 'ms-MY', label: 'MY' },
            { key: 'vi-VN', label: 'VN' },
        )
      } else {
        this.lang.push(
            { key: "id-ID", label: "ID" })
      }
    } else {
      let currencyEnum = utils.getCurrencyByCode(this.currency)
      this.lang.push({
        key:currencyEnum.language , label: currencyEnum.country
      })
    }

    this.lang.forEach(element => {
      if (currentLang.indexOf(element.key) !== -1) {
        this.country = element.label
        i18n.locale = element.key
        this.$global.lang = element.label
        this.$store.commit('setCountry', element.label);
      }
    });
  },
  methods: {
    getImageRealPath(img) {
      return utils.getImagePath(img)
    },
    close() {
      this.isSearchShow = false;
      this.showSearchFlag = false;
    },
    showSearch() {
      this.showSearchFlag = true;
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    clearValue() {
      this.searchVal = '';
    },
    clearChildValue() {
      this.$refs.search.clearValue();
    },
    toPayCheckout(gameId) {
      this.showSearchFlag = false;
      this.clearChildValue()
      this.isSearchShow = false;
      this.$router.push({
        path: `/game/${gameId}`
      })
    },
    highlightedItem(gameName) {
      if (!this.searchVal) {
        return gameName;
      }

      const pattern = new RegExp(`(${this.searchVal})`, 'gi');
      const highlighted = gameName.replace(pattern, '<span class="highlighted">$1</span>');

      return highlighted;
    },
    // 搜索接口
    fuzzyQuery(gameId) {
      if (gameId === '') return ;
      this.isSearchShow = false;
      const formdata = {
        gameName: gameId,
        lang: this.country != null && this.country != '' ? this.country.toString().toLowerCase() : "en",
      }
      this.$httpReqest('/tikishop/game/fuzzyQuery', formdata)
        .then((res) => {
          if (res.code == '200') {
            this.gameListInfo = res.gameListInfo
            this.isSearchShow = true;
          } else {
            console.log(res.msg)
          }
        })
        .catch((err) => {
          console.log('queryCoinTopupList err!', err)
        })
    },
    handleSearch(keyword) {
      // 在这里实现搜索
      this.searchVal = keyword
      this.fuzzyQuery(keyword)
    },
    changeLang(obj) {
      const item = this.lang[obj.key]
      this.country = item.label
      localStorage.setItem('lang', JSON.stringify(item.key))
      i18n.locale = item.key
      this.$global.lang = item.label; // 更新全局变量的值
      this.$store.commit('setCountry', item.label);
    },
  },
}
</script>

<style lang="scss" scoped>
.header-main {
  .mask {
    position: fixed;
    z-index: 14;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(56, 47, 94, 0.93);
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1236px;
  margin: 0 auto;
  .logo-hd-box {
    text-align: left;
    a {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  .lang-box {
    text-align: right;
    display: flex;
  }
  .ant-row-flex{
    width: 100%;
    z-index: 15;
    position: relative;
  }
  .logo-hd {
    width: auto;
    height: 37px;
  }
  .search {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 11;
    width: 100%;
    .ant-input-search {
      width: 100% !important;
    }
    .search-result-box {
      position: absolute;
      top: 80px;
      width: 100%;
      z-index: 20;
      border-radius: 4px;
      max-height: 300px;
      overflow-y: scroll;
    }
  }

  .ant-dropdown-link {
    color: #fff;
    font-family: MicrosoftYaHeiSemibold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px;
  }

  .navbar-nav {
    margin-bottom: 0;
  }

  .dropdown {
    margin-bottom: 0;
    position: relative;
  }

  .dropdown-menu {
    position: absolute;
    background: #222222;
    border: 1px solid #414141;
    border-radius: 0;
    top: 48px;
    right: 0;
  }

  .dropdown-menu li {
    padding: 0 28px;
    height: 36px;
  }

  .dropdown-menu li a {
    height: 36px;
    line-height: 36px;
    vertical-align: top;
  }

  .dropdown-menu a {
    font-size: 14px;
    color: #000;
  }

  .dropdown-toggle {
    font-size: 14px;
    color: #ffffff;
    vertical-align: top;
    line-height: 28px;
    display: block;
  }

  .dropdown-toggle:hover {
    text-decoration: none;
  }

  .icon-country {
    width: 28px;
    height: 28px;
    margin-right: 15px;
    display: block;
    background-size: 28px auto;
    &.icon-country-en {
      background-image: url('../assets/image/pc/uk.png') ;
    }
    &.icon-country-id {
      background-image: url('../assets/image/pc/id.png') ;
    }
    &.icon-country-th {
      background-image: url('../assets/image/pc/th.png') ;
    }
    &.icon-country-ph {
      background-image: url('../assets/image/pc/ph.png') ;
    }
    &.icon-country-mm {
      background-image: url('../assets/image/pc/mm.png') ;
    }
    &.icon-country-br {
      background-image: url('../assets/image/pc/br.png') ;
    }
    &.icon-country-mx {
      background-image: url('../assets/image/pc/mx.png') ;
    }
    &.icon-country-vn {
      background-image: url('../assets/image/pc/vn.png') ;
    }
    &.icon-country-my {
      background-image: url('../assets/image/pc/my.png') ;
    }
  }


  .ant-dropdown-link > .anticon.anticon-down {
    vertical-align: middle;
    margin-left: 10px;
  }

  .header-right {
    display: flex;
    align-items: center;
  }

  .search {
    margin-right: 20px;
    color: #fff;
    .no-data {
      margin-bottom: 0;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      padding: 14px 10px;
      background: #fff;
      color: #000;
    }

    .search-result-list {
      margin-bottom: 0px;
      li {
        display: flex;
        color: #fff;
        border-bottom: 1px solid rgba(144, 129, 255, 0.24);
        &:last-child {
          border: none;
        }

        .content {
          width: 100%;
          display: flex;
          padding: 20px 0;
          align-items: center;
        }
        .name {
          margin-bottom: 0;
          line-height: 1.2;
          text-align: left;
          font-size: 16px;
        }
        .game-pic {
          width: 64px;
          height: 64px;
          border-radius: 6px;
          overflow: hidden;
          margin-right: 21px;
          background: #324B4A;
          position: relative;
          &:before {
            content: '';
            background-image: url('../assets/image/kuku-load.png');
            background-size: 37px auto;
            width: 37px;
            height: 35px;
            left: 50%;
            top: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
          }
          img {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
          }
        }
      }
    }
  }
}
.mobile {
  .header-main  {
    display: block;
    padding-top: .35rem;
    .search-icon {
      width: px2rem(40);
      height: px2rem(40);
      background: url('../assets/image/wap/search.png') no-repeat;
      display: inline-block;
      margin-right: px2rem(44);
      vertical-align: top;
      background-size: px2rem(40) auto;
      margin-top: px2rem(8);

    }
    .row-2 {
      margin-top: px2rem(47);
    }
    .logo-hd-box {
      height: px2rem(55);
      a {
        display: block;
      }
    }
    .logo-hd {
      width: px2rem(225);
      height: px2rem(55);
      vertical-align: top;
    }
    .lang-box {
      height: px2rem(55);

    }
    .ant-dropdown-link {
      font-size: px2rem(31);
      height: px2rem(55);
      display: inline-flex;
      vertical-align: top;

    }
    .icon-country {
      width: px2rem(48);
      height: px2rem(48);
      margin-right: px2rem(26);
      background-size:  px2rem(48) auto;
    }
    .search {
      width: 100%;
      margin-right: 0;
    }
    .search-result-box {
      position: absolute;
      border: none;
      top: px2rem(86);
      width: 100%;
      z-index: 20;
      border-radius: none;
      max-height: px2rem(800);
      overflow-y: scroll;

      li {
        padding: 0 px2rem(20);
        border-radius: .2rem;
        margin-bottom: .22rem;
        .content {
          padding: px2rem(20) 0;
          font-size: px2rem(28);
        }
        .game-pic {
          width: px2rem(88);
          height: px2rem(88);
          border-radius: px2rem(7);
          margin-right: px2rem(30);
          &:before {
            background-size: px2rem(37) auto;
            width: px2rem(37);
            height: px2rem(35);
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.highlighted {
  color: #C100FF;
}
.search-input {
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    text-align: left;
    padding-left: 35px;
  }
}
.ant-dropdown {
  .lang-menu  {
    background: #fff;
    border: 1px solid #e3e3e3;
    padding: 0;
  }
  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background: rgba(255, 255, 255, 0.14);
  }
  .ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #222;
    line-height: 20px;
    height: 58px;
    width: 168px;
    display: flex;
    align-items: center;
    .icon-country {
      width: 40px;
      height: 20px;
      margin-right: 20px;
      background-repeat: no-repeat;
      background-size: 40px auto;
      &.icon-country-EN {
        background-image: url('../assets/image/uk.png');
      }
      &.icon-country-ID {
        background-image: url('../assets/image/id.png');
      }
      &.icon-country-MM {
        background-image: url('../assets/image/mm.png');
      }
      &.icon-country-VN {
        background-image: url('../assets/image/vn.png');
      }
      &.icon-country-BR {
        background-image: url('../assets/image/br.png');
      }
      &.icon-country-MX {
        background-image: url('../assets/image/mx.png');
      }
      &.icon-country-MY {
        background-image: url('../assets/image/my.png');
      }
      &.icon-country-PH {
        background-image: url('../assets/image/ph.png');
      }
      &.icon-country-TH {
        background-image: url('../assets/image/th.png');
      }
    }
  }

}
</style>