/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2023-06-16 00:08:53
 * @LastEditors: tina.cai
 * @LastEditTime: 2023-06-16 00:42:14
 */
export default {
    /**
     * 获取图片路径
     */
    getImagePath(img) {
        if (!img) return;
        return `https://tikipay-static-resource.oss-cn-shenzhen.aliyuncs.com/tikiShop/gamePic/${img}`

    },

    getLanguageByCurrency(currency) {
        switch (currency) {
            case "IDR":
                return "id-ID";
            case "MYR":
                return "ms-MY";
            case "PHP":
                return "fil-PH";
            case "THB":
                return "th-TH";
            case "BRL":
                return "pt-BR";
            case "MXN":
                return "es-MX";
            default:
                return "en-US";
        }
    },

    getCurrencyByCode(currencyCode) {
        for (const code in CurrencyEnum) {
            if (CurrencyEnum[code].currency === currencyCode) {
                return CurrencyEnum[code];
            }
        }
        return null;
    },

}


const CurrencyEnum = {
    IDR: {value: 1, currency: "IDR", countryCode: 360, country: "ID", language: "id-ID"},
    THB: {value: 2, currency: "THB", countryCode: 764, country: "TH", language: "th-TH"},
    VND: {value: 3, currency: "VND", countryCode: 704, country: "VN", language: "vi-VN"},
    CNY: {value: 4, currency: "CNY", countryCode: 156, country: "CN", language: "zh-CN"},
    INR: {value: 5, currency: "INR", countryCode: 356, country: "IN", language: "hi-IN"},
    PHP: {value: 6, currency: "PHP", countryCode: 608, country: "PH", language: "fil-PH"},
    MYR: {value: 7, currency: "MYR", countryCode: 458, country: "MY", language: "ms-MY"},
    SGD: {value: 8, currency: "SGD", countryCode: 702, country: "SG", language: "en-SG"},
    MMK: {value: 9, currency: "MMK", countryCode: 104, country: "MM", language: "my-MM"},
    BRL: {value: 10, currency: "BRL", countryCode: 986, country: "BR", language: "pt-BR"},
    MXN: {value: 11, currency: "MXN", countryCode: 484, country: "MX", language: "es-MX"}
};
