
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index3',
    component: () => import(/* webpackChunkName: "tikmobileC" */ '@/views/homeC.vue')
  },
  {
    path: '/games',
    name: 'productListC',
    component: () => import(/* webpackChunkName: "productListC" */ '@/views/productListC.vue')
  },
  {
    path: '/game/:id',
    name: 'payCheckoutC',
    component: () => import(/* webpackChunkName: "payCheckoutC" */ '@/views/payCheckoutC.vue')
  },
  {
    path: '/orderConfirm/:gameId/:orderNo',
    name: 'payConfirmC',
    component: () => import(/* webpackChunkName: "payConfirmC" */ '@/views/payConfirmC.vue')
  },
  {
    path: '/payResult',
    name: 'payResultC',
    component: () => import(/* webpackChunkName: "payResultC" */ '@/views/payResultC.vue')
  },
  {
    path: '/policies',
    name: 'rprivacy',
    component: () => import(/* webpackChunkName: "rprivacy" */ '@/views/rprivacy.vue')
  },
  {
    path: '/term',
    name: 'reula',
    component: () => import(/* webpackChunkName: "reula" */ '@/views/reula.vue')
  },
  {
    path: '/rule',
    name: 'raction',
    component: () => import(/* webpackChunkName: "raction" */ '@/views/raction.vue')
  }
  
]

const routerC = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default routerC
