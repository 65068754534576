<!--
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2023-05-20 15:22:38
 * @LastEditors: tina.cai
 * @LastEditTime: 2023-06-20 00:28:12
-->
<template>
  <div class="search-input">
    <a-input
      v-model="inputValue"
      :placeholder="$t('search')"
      @change="handleSearch"
      :allowClear="true"
    >
      <template #prefix>
        <a-icon type="search" />
      </template>
    </a-input>
  </div>

</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    searchVal: {
      type: String,
      required: true
    },
    clear: {
      type: Function,
      required: true
    }
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    }
  },
  data() {
    return {
      inputValue: this.searchVal
    }
  },
  methods: {
    handleSearch: debounce(function() {
      // 在这里处理搜索逻辑
      // console.log('搜索内容：', this.inputValue)
      // 触发 onsearch 事件
      this.$emit('onsearch', this.inputValue)
    }, 500),
    clearValue() {
      this.inputValue = '';
      this.clear();
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  display: flex;
  width: 100%;
}
</style>


<style lang="scss" >
.search-input {
  .ant-input-affix-wrapper .ant-input {
    background: #222222;
    border: 1px solid #544d4d;
  }
  .ant-input-affix-wrapper .ant-input-prefix, .ant-input-affix-wrapper .ant-input-suffix {
    color: rgba(255, 255, 255, .8);
  }
  .ant-input-affix-wrapper .ant-input {
    color: #Fff;
  }
  .ant-input-clear-icon {
    color: rgba(255, 255, 255, .8);
    &:hover{
      color: rgba(255, 255, 255, 1);
    }
  }
}
.mobile {
  .search-input {
    .ant-input-affix-wrapper .ant-input {
      font-size: px2rem(24);
      height: px2rem(70);
    }
  }
}
</style>